import React from 'react'
import Link from 'gatsby-link'
import BitbucketLogo from '../assets/bitbucket.svg'
import GithubLogo from '../assets/github.svg'
import OrcidLogo from '../assets/orcid.svg'
import ResumeLogo from '../assets/resume.svg'
import ScholarLogo from '../assets/scholar.svg'

const Header = () => (
  <div style={{
    marginBottom: '1.45rem',
  }} >
    <div className="primary-color">
      <div style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '3.45rem 1.0875rem',
        textAlign: 'center',
      }} >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: 'white',
              textDecoration: 'none',
            }}
          >
            Muhammad Iqbal C. Rochman
          </Link>
        </h1>
        <div style={{
          borderBottom: '8px solid rgb(116, 164, 203)',
          marginTop: '1.2rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: 100,
          display: 'block',
        }} />
      </div>
    </div>
    <div className="secondary-color">
      <div style={{
        display: 'flex',
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1rem',
        justifyContent: 'flex-end',
      }} >
        <div style={{ margin: '0 1rem', width: 50, }}><a href="https://orcid.org/0000-0001-6484-6986">
          <img style={{ marginBottom: 0 }} src={OrcidLogo} alt="ORCiD page" title="ORCiD" />
        </a></div>
        <div style={{ margin: '0 1rem', width: 50, }}><a href="https://scholar.google.com/citations?hl=en&user=X_OyZrIAAAAJ">
          <img style={{ marginBottom: 0 }} src={ScholarLogo} alt="Google Scholar page" title="Google Scholar" />
        </a></div>
        <div style={{ margin: '0 1rem', width: 50, }}><a href="https://docs.google.com/document/d/1cWYesPS6ZQPEgunDc5W3etUuxzHWU9_k9w6YO8zCOO4/edit?usp=sharing">
          <img style={{ marginBottom: 0 }} src={ResumeLogo} alt="Resume" title="Resume" />
        </a></div>
        <div style={{ margin: '0 1rem', width: 50, }}><a href="https://github.com/kyuucr">
          <img style={{ marginBottom: 0 }} src={GithubLogo} alt="Github page" title="Github" />
        </a></div>
        <div style={{ margin: '0 1rem', width: 50, }}><a href="https://bitbucket.org/kyuucr">
          <img style={{ marginBottom: 0 }} src={BitbucketLogo} alt="Bitbucket page" title="Bitbucket" />
        </a></div>
      </div>
    </div>
  </div>
)

export default Header
